.content-wrapper {
  display: flex;
  flex-direction: row;

  &__left {
    width: 50%;
    background-color: @white;
    min-width: 28rem;
    z-index: 100;
  }

  &__right {
    width: 50%;
    min-height: 100vh;
    background-color: @black;
    display: flex;
    align-items: center;
    justify-content: center;
    position: sticky;
    top: 0;
  }
}
