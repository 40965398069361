.f-container {
  display: flex;
  flex-direction: column;
  width: 91%;
  align-items: center;
  margin: auto;
  text-align: center;

  &__mobile {
    margin-top: 1.6rem;
  }

  &__tick-logo {
    position: fixed;
    top: 30vh;

    &--mobile {
      margin-top: 4.5rem;
    }
  }

  &__thank-you {
    margin-top: 5rem;
    margin-bottom: 0;
    font-size: 38px;
    font-weight: 700;
    line-height: 38px;

    &--mobile {
      margin-top: 4.4rem;
    }
  }

  &__enjoy {
    margin-top: 1.2rem;
    margin-bottom: 0;
    font-size: 20px;
    font-weight: 700;
    line-height: 22px;

    &--mobile {
      margin-top: 1.3rem;
    }
  }

  &__install {
    margin-top: 4.8rem;
    font-size: 14px;
    font-weight: 400;
    line-height: 25px;

    &--mobile {
      margin-top: 1.9rem;
    }
  }

  &__google-logo {
    margin-top: 1rem;
  }

  &__app-store-logo {
    margin-top: 0.1em;
    margin-bottom: 1em;
  }
}
