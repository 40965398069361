@import "./reset.less";

@background-color: #f4f4f4;
@primary-color: #1e1e1e;
@secondary-color: #fafafa;
@text-extra-color: #010747;
@primary-content-color: white;
@secondary-content-color: #969696;
@error-color: #ff5260;
@white: #fafafa;
@black: #1e1e1e;
@tooltip-border-color: #f0f3f8;
@tooltip-background-color: #ffffff;
@input-border-color: #f0f3f8;
@input-placeholder-color: #bbbbbb;
@input-label-color: #585858;
@primary-hover-color: #585858;

html {
  font-size: 14px;
}

body {
  background-color: #f4f4f4;
  overflow-x: hidden;
  font-family: "Poppins", sans-serif;
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  color: #1f1f1f;
  width: 100vw;
  height: 100vh;
}

input {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
  -webkit-appearance: none;
  -moz-appearance: none;
}

h1,
h2,
h3,
h4 {
  font-family: "Sora", sans-serif;
  font-size: 14px;
}

button {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
}

@import "./styles/desktop.less";
@import "./styles/mobile-main.less";
@import "./styles/desktop-main.less";
@import "./styles/mobile-form.less";
@import "./styles/desktop-form.less";
@import "./styles/mobile-finish.less";
@import "./styles/desktop-finish.less";
@import "./styles/toast-notification.less";
@import "./styles/number-input.less";

