@border-color: #e4e4eb;

.m-form-container {
  margin-left: 0;
  margin-right: 0;
  width: -webkit-fill-available;

  &__mobile {
    padding-left: 2.1rem;
    padding-right: 2.1rem;
    padding-top: 1.6rem;
  }

  &__form-nav {
    display: flex;
    align-items: stretch;
    width: 100%;
    justify-content: space-between;

    &__back {
      font-size: 20px;
      line-height: 22px;
      color: #2f323b;
      font-weight: 600;
      margin-top: 0;
      margin-bottom: 0;
    }

    &__button {
      border: 0;
      background-color: transparent;
      display: inline-flex;
      align-items: center;
      padding-left: 0;

      &:focus {
        outline: none;
        border: none;
      }

      &__arrow {
        margin-right: 1em;
      }
    }

    &__logo {
      width: 6.6rem;
    }
  }

  &__form {
    margin-top: 2.3rem;
    width: 100%;

    &__button {
      margin-top: 1.5rem;
      width: 100%;
      height: 3.3rem;
      border-radius: 8px;
      background-color: @primary-color;
      border: none;
      color: @primary-content-color;
      margin-bottom: 1em;
      line-height: 25px;
      font-weight: 400;
      font-size: 14px;

      &:hover {
        background-color: @primary-hover-color;
      }

      &__processing {
        opacity: 0.2;
      }

      &:focus {
        outline: none;
      }
    }
  }

  &__type {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 2.2rem;

    &__button {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      width: -webkit-fill-available;
      height: 6.4rem;
      border-radius: 8px;
      border: 1px solid @border-color;
      background-color: @secondary-color;
      color: #969696;
      flex-grow: 1;
      line-height: 25px;
      font-weight: 400;
      font-size: 14px;
      padding-top: 1.3rem;
      padding-bottom: 1.3rem;
      margin-left: 0.35rem;

      &__first {
        margin-right: 0.35rem;
        margin-left: 0;
      }

      &:focus {
        outline: none;
      }

      &__icon {
        // margin-bottom: 0.6rem;
        width: 1.4rem;
      }

      &--active {
        border-width: 2px;
        border-color: @black;
        color: @black;
        font-weight: 400;
      }
    }
  }
}

.label {
  width: 100%;
  display: flex;
  flex-direction: column;
  color: #2f323b;
  margin-top: 1.1rem;

  &__error {
    color: #fb0020;
    font-size: 12px;
  }

  &__description {
    width: 100%;
    display: flex;
    justify-content: space-between;
    line-height: 22px;
    color: @input-label-color;
  }

  &__field {
    display: flex;
    width: 100%;
    align-items: center;

    &__input {
      -moz-appearance: textfield;
    }

    &__select {
      width: 20%;
    }

    &__input {
      border: 1px solid @input-border-color;
      border-radius: 8px;
      height: 3.1rem;
      width: 100%;

      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &[type="text"] {
        padding-left: 1em;
      }

      &[type="number"] {
        padding-left: 1em;
      }

      &[type="password"] {
        padding-left: 1em;
      }

      &::placeholder {
        color: @input-placeholder-color;
      }

      &:focus,
      &:hover {
        outline: none;
        border: 1px solid @black;
        border-radius: 8px;
      }
    }

    &__input--number {
      border: 1px solid @border-color;
      border-radius: 8px;
      height: 3.1rem;
      width: 80%;

      &:focus {
        outline-color: @primary-hover-color;
      }
    }
  }
}

.error {
  border-color: @error-color;
}

.label-container {
  margin-top: 1.1rem;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: #4a5767;

  &__checkbox-container {
    display: inline-block;
    margin-right: 0.5em;
    position: relative;
    cursor: pointer;
    width: 18px;
    min-width: 18px;
    height: 18px;
    min-height: 18px;
    background: white;
    border: 1px solid #e4e4eb;
    box-sizing: border-box;
    border-radius: 4px;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;

    & input {
      display: none;
    }

    &__error-consent {
      border-color: @error-color;
      border-radius: 4px;
    }

    &__checkmark {
      background-color: black;
      width: 10px;
      height: 10px;
    }

    & input:checked + .checkmark {
      background: @primary-color;
      display: inline-block;
      width: 18px;
      height: 18px;
      position: absolute;
      border-radius: 4px;
    }

    & input:checked + .checkmark:after {
      transform: scale(1.3);
      content: "";
      position: absolute;
      top: 6px;
      left: 5px;
      width: 10px;
      height: 9px;
      background-image: url(/assets/checkbox-tick.svg);
      background-repeat: no-repeat;
    }
  }

  &__required {
    color: @error-color;
  }

  &__required-legend {
    color: @error-color;
  }

  &__more {
    color: @primary-color;
    cursor: default;
    padding-left: 5px;
    &__arrow {
      margin: 0 0 0.2em 0.3em;

      &--reverse {
        transform: rotate(0.5turn);
      }
    }
  }
}

.more-consents {
  margin-left: 2em;
}

.consents {
  &__item {
    margin-top: 1em;
  }
}

.regulations-consent-link {
  color: @primary-color;
  text-decoration: none;

  & :hover,
  :active,
  :visited,
  :focus {
    text-decoration: none;
    color: @primary-color;
  }
}
