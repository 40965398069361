.charger-logo {
  height: 23em;
  // margin-left: -65%;
}

.m-container {
  display: flex;
  flex-direction: column;
  // width: 91%;
  align-items: center;
  margin: auto;

  &__background {
    position: absolute;
    top: 0;
    right: 0;
  }

  &__language {
    margin-left: 2.1rem;
    margin-top: 1rem;
    align-self: flex-start;
  }

  &__charger {
    // width: 35%;
    // text-align: left;
    position: absolute;
    right: -8.5em;
    top: 0;
  }

  &__create {
    margin-bottom: 0.1em;
    // margin-top: 23%;
    align-self: flex-start;
    margin-top: 0;
    line-height: 22px;
    font-size: 20px;
    margin-left: 2.1rem;
    font-weight: 600;
  }

  &__m-header {
    display: flex;
    width: -webkit-fill-available;
    // height: 31.7rem;
    font-weight: 700;
    color: #2b2c35;
    margin-left: 2.1rem;
    padding-bottom: 8.2rem;

    &__title {
      font-size: 38px;
      font-weight: 700;
      line-height: 100%;
      margin-bottom: 0;
      margin-top: 2.1rem;
    }

    &__nexity {
      margin-top: 5.5rem;
      display: flex;
      flex-direction: column;
      // height: 40vh;
    }

    &__logo {
      width: fit-content;
    }

    &__join {
      margin-top: 1.9rem;
      align-self: flex-start;
      font-size: 18px;
      line-height: 25px;
      margin-left: 2.1rem;
    }
  }

  &__contact {
    background-color: transparent;
    border-radius: 8px;
    border: 1px;
    margin-top: 0.7rem;
    width: -webkit-fill-available;
    height: auto;
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    margin-left: 2.1rem;
    margin-right: 2.1rem;

    &__call-logo {
      margin-right: 0.5em;
      height: 1em;
    }

    &__text {
      margin: 0.9rem 0 0 1.4rem;
      font-weight: 500;
      line-height: 24px;
      font-size: 18px;
    }

    &__number {
      display: flex;
      margin: 0.2rem 0 0.9rem 1.4rem;
      color: @black;
      text-decoration: none;
      background-color: transparent;
      font-size: 12px;
      line-height: 16px;

      &:hover,
      :visited,
      :focus {
        color: #0056b3;
        text-decoration: underline;
      }
    }
  }

  &__right {
    width: 50%;
    text-align: center;
    margin-right: 10%;
  }
}

.m-operator {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 0.4rem;

  &__button {
    border: none;
    border-radius: 8px;
    width: -webkit-fill-available;
    height: 6.5em;
    margin-left: 2.1rem;
    margin-right: 2.1rem;

    &--green {
      background-color: @primary-color;
      margin-top: 0.9rem;
    }

    &--white {
      background-color: @secondary-color;
      border: 1px solid #e4e4eb;
    }

    &__content {
      display: flex;
      justify-content: space-between;

      &__item--logo {
        width: 1.1rem;
        margin-left: 2rem;
      }

      &__item--name {
        text-align: left;
        display: flex;
        align-items: center;
        font-weight: 500;
        line-height: 25px;
        justify-self: flex-end;
        margin-right: 2rem;
      }

      &--white {
        color: @primary-content-color;
      }

      &--gray {
        color: @secondary-content-color;
      }
    }
  }
}
