.d-container {
  display: flex;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 6rem;

  &__create {
    font-size: 20px;
    font-weight: 600;
    line-height: 22px;
    margin-top: 4.7rem;
    margin-bottom: 0;
  }

  &__join {
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    margin-top: 1.8rem;
    margin-bottom: 0;
  }

  &__header {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  &__title {
    font-size: 38px;
    font-weight: 700;
    line-height: 100%;
    margin-top: 4.7rem;
    margin-bottom: 0;
  }

  &__contact {
    border-radius: 8px;
    border: 1px;
    margin-top: 1.3rem;
    width: 22.5rem;
    display: flex;
    align-items: center;
    margin-left: 1.1rem;

    &__call-logo {
      margin-right: 0.5em;
      // height: 1em;
      width: 1.3rem;
    }

    &__text {
      margin-left: 1.4rem;
      margin-top: 1rem;
      font-weight: 400;
      line-height: 24px;
      font-size: 18px;
      font-family: "Sora", sans-serif;
    }

    &__number {
      display: flex;
      margin-top: 0.2rem;
      margin-left: 1.4rem;
      color: @black;
      text-decoration: none;
      background-color: transparent;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;

      &:hover,
      :focus {
        color: #0056b3;
        text-decoration: underline;
      }

      &:visited {
        color: @text-extra-color;
        text-decoration: none;
      }
    }
  }

  &__text {
    margin-bottom: 0.5em;
    margin-top: 2.4em;
  }
}

&__right {
  width: 55%;
  position: sticky;
  margin-left: -10em;
  z-index: -1;
  align-self: flex-start;
  margin-top: 4.5em;
  align-items: center;

  &__charger {
    width: 56.9rem;
  }
}

.d-operator {
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;

  &__button {
    border: none;
    border-radius: 8px;
    width: 100%;
    height: 6.4rem;
    padding: 0px;

    &:focus {
      outline: none;
    }

    &--black {
      background-color: @primary-color;
      margin-top: 1rem;
      margin-bottom: 0;
    }

    &--white {
      background-color: @secondary-color;
      border: 1px solid @black;
    }

    &__content {
      display: flex;
      justify-content: space-between;

      &__item--logo {
        width: 1.1rem;
        margin-left: 2rem;
      }

      &__item--name-container {
        text-align: left;
        margin-right: 2.2rem;
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 25px;
        justify-self: flex-end;

        &__question {
          margin-left: 0.4rem;
        }
      }

      &--white {
        color: @primary-content-color;
      }

      &--black {
        color: @black;
      }
    }
  }
}

.tooltip {
  background-color: @tooltip-background-color !important;
  width: 16rem !important;
  opacity: 1 !important;
  border-radius: 8px !important;
  border: 1px solid @tooltip-border-color !important;

  &.place-top {
    &:after {
      left: 12.3rem !important;
      margin-left: 0 !important;
      border-top-color: @tooltip-background-color !important;
    }
  }
}

.language {
  align-self: flex-start;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;

  &__arrow {
    margin: 0 0 0.1em 0.8rem;
  }
}
