.form-container {
  display: flex;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 6rem;
}

.form-d-button {
  font-size: 16px;
}
