.nx-toast-container {
  position: fixed;
  display: flex;
  justify-content: space-between;
  background: white;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 1em 1.5em 1em 1.5em;
  right: 1em;
  top: 1em;
  align-items: center;
  max-width: 30vh;

  &__mobile {
    width: 86%;
    max-width: none;
    padding: 4% 3% 4% 3%;
  }

  &--error {
    border-top: 5px solid @error-color;
  }

  &__content {
    order: 2;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    color: #4f4f4f;
    padding-left: 20px;
    padding-right: 20px;
    margin: 0;
    word-break: break-word;
  }

  &__button {
    order: 3;
    background-image: url("/assets/cross.svg");
    background-repeat: no-repeat;
    min-width: 10px;
    max-width: 10px;
    min-height: 10px;
    max-height: 10px;
    cursor: pointer;
    align-self: flex-start;
  }

  &__icon {
    order: 1;
    min-width: 16px;
    width: 16px;
    min-height: 16px;
    height: 16px;
    background-repeat: no-repeat, repeat;
    align-self: flex-start;

    &--error {
      background-image: url("/assets/toast-error.svg");
    }
  }
}
