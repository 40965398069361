.select {
  display: flex;
  border: 1px solid #f0f3f8;
  border-radius: 8px;
  background-color: white;
  width: 100%;

  &--error {
    border-color: @error-color;
  }

  &:hover,
  &--focused {
    border-color: @black;
  }

  &__picker {
    width: 5rem;
  }

  &__input {
    width: 60%;
    border: none;
    padding-left: 1rem;
    font-size: 14px;
    line-height: 25px;
    margin-left: 1px;
    outline: transparent;

    &::placeholder {
      color: @input-placeholder-color;
    }
  }

  &__dropdown {
    &__calling-node {
      width: 30%;
    }
  }
}
